.FeaturedList {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  overflow-x: scroll;
}

.FeaturedListEventPage {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  overflow-x: scroll;
  /* padding-inline: 36vw; */
}

/* New landing page DefaultLandingPage.css */
.gradient-text {
  background: linear-gradient(
    135deg,
    #11b8ff,
    #1d46ff
  ); /* Adjust colors as needed */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.learn-more-button {
  background-color: #fff;
  color: #000;
  border: 2px solid #000;
}

.hero-image img {
  max-width: 100%;
  height: auto;
  position: absolute;
  right: -11%;
  top: -60px;
  transition: 0.3s ease;
}

.landingPageTitle {
  font-size: 90px;
  font-weight: 500;
  line-height: 0.97;
  white-space: nowrap;
}

.subtitleText {
  font-size: 20px;
  color: #929292;
}

@media (max-width: 1650px) {
  .hero-image img {
    max-width: 70%;
    height: auto;
  }

  .landingPageTitle {
    font-size: 80px;
  }

  .subtitleText {
    font-size: 18px;
  }
}

@media (max-width: 1450px) {
  .hero-image img {
    max-width: 70%;
    height: auto;
  }

  .landingPageTitle {
    font-size: 60px;
  }

  .subtitleText {
    font-size: 16px;
  }
}

@media (max-width: 1200px) {
  .hero-image img {
    max-width: 70%;
    height: auto;
    right: -40%;
  }
}

@media (max-width: 950px) {
  .landingPageContainer {
    display: flex;
    flex-direction: column;
  }

  .hero-image img {
    max-width: 70%;
    height: auto;
    position: relative;
    right: 0;
    top: 0;
  }
}

.RectangleButton {
  width: 100%;
  gap: 7px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 0.6em;
  border: 0px solid black;
}

.Button {
  display: inline-block;
  position: relative;
}

.Button::after {
  content: "";
  position: absolute;
  box-sizing: border-box;
  border: 1px solid black;
  border-radius: 50%;
  width: 2em;
  height: 2em;
  left: -3px;
  top: -3px;
}

.MultiTicketPrompt {
  font-size: 12px;
  color: #929292;
}

.SelectDetails {
  display: flex;
  flex-direction: row;
  padding: 10px;
}

.TicketDescription {
  font-size: 12px;
}

.TicketText {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
}

.TicketPrice {
  font-size: 16px;
  font-weight: 500;
}

.SelectContainer {
  width: 100%;
  border-radius: 8px !important;
  background-color: #f4f5f6;
  border: 1px solid #007aff;
}

/** AdminDashboard */
.AdminDashboardContainer {
  background-color: #000;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.AdminDashboardBody {
  display: flex;
  flex-direction: column;
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 5em;
}

.AdminDashboardHeader {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
}

.AdminDashboardList {
  margin-top: 10px;
  height: 600px;
  overflow-y: scroll;
}

.AdminDashboardListItem {
  padding: 8px;
  overflow-x: scroll;
  width: 600px;
  border: 0.75px solid #eaeaf1;
}

.AdminDashboardSectionTitle {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
}

.AdminDashboardSubtext {
  color: #fff;
  font-size: 14px;
}

/** Admin Dashboard Events */
.AdminDashboardEventsContainer {
  background-color: #000;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.AdminDashboardEventsList {
  margin-top: 10px;
  overflow-y: scroll;
}

.AdminDashboardListItemEvent {
  padding: 8px;
  overflow-x: scroll;
  border: 0.75px solid #eaeaf1;
}

.AdminFeatureEventSelectionBox {
  background: white;
  border-radius: 8px;
  padding-inline: 16px;
  padding-block: 8px;
  gap: 4px;
  font-size: 14px;
}

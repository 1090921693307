.ProfileFullName {
  width: 90%;
  color: #000;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  margin-top: 0em;
  margin-bottom: 0em;
  font-size: 1.7em;
}

.ProfileHeaderContainer {
  background-color: #fff;
  padding: 1em;
  box-shadow: #000;
}

.ProfilePageContainer {
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.ProfilePageCenterText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  align-self: center;
  text-align: center;
}

.ProfilePageBlackButton {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  align-self: center;
  align-items: center;
  flex-direction: column;
}

.ProfilePageBlackButtonText {
  padding: 10px;
  background-color: black;
  color: white;
  border-radius: 100px;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
}

.ProfilePageGraySubtitle {
  font-size: 12px;
  color: #929292;
  text-align: center;
}

.ProfilePageBlankEvents {
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-right: -14px;
  overflow-x: hidden;
}

.ProfilePageBlankSquare1Desktop {
  background-color: white;
  width: clamp(210px, calc((100vw - (36vw + 3 * 24px)) / 4), 420px);
  height: clamp(210px, calc((100vw - (36vw + 3 * 24px)) / 4), 420px);
  border-radius: 6px;
  margin-bottom: 9px;
}

.ProfilePageBlankSquare2Desktop {
  background-color: white;
  width: calc(((100vw - (36vw + 3 * 24px)) / 4) * (184 / 220));
  height: 19px;
  margin-bottom: 6px;
}

.ProfilePageBlankSquare3Desktop {
  background-color: white;
  width: calc(((100vw - (36vw + 3 * 24px)) / 4) * (118 / 220));
  height: 19px;
}

.ProfilePageBlankSquare1 {
  background-color: white;
  width: 139px;
  height: 139px;
  border-radius: 6px;
  margin-bottom: 9px;
}

.ProfilePageBlankSquare2 {
  background-color: white;
  width: 110px;
  height: 12px;
  margin-bottom: 6px;
}

.ProfilePageBlankSquare3 {
  background-color: white;
  width: 87px;
  height: 12px;
}

.ProfilePic {
  display: block;
  height: 4em;
  width: 4em;
  border-radius: 2em;
  object-fit: cover;
}

.ProfileUniversity {
  color: #929292;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-size: 0.8em;
  font-weight: 600;
}

.StandardAlignedRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.InfoBoxBrowser {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
}

.ProfileBrowserContainer {
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ProfileBrowserEditFullName {
  box-sizing: border-box;
  color: #000;
  width: clamp(314px, 24vw, 414px);
  display: block;
  font-size: 24px;
  font-weight: 600;
  border: 0px solid black;
  padding-inline: 8px;
  padding-block: 4px;
  border-radius: 12px;
  background-color: #ededed;
}

.ProfileBrowserEditFullName:focus {
  box-shadow: none;
  outline: none;
}

.ProfileBrowserSocialMedia {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.ProfileBrowserEditSocialMediaContainer {
  background-color: #ededed;
  width: 100%;
  overflow: hidden;
  border-radius: 12px;
}

.ProfileBrowserEditSocialMediaIcon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 14px;
}

.ProfileBrowserSocialMediaIcon {
  transition: 0.1s ease;
}

.ProfileBrowserSocialMediaIcon:hover {
  cursor: pointer;
  filter: brightness(0);
}

.ProfileBrowserEditSocialMedia {
  box-sizing: border-box;
  color: #000;
  width: clamp(314px, 24vw, 414px);
  display: block;
  font-size: 14px;
  font-weight: 500;
  border: 0px solid black;
  font-weight: 500;
  background-color: #ededed;
  padding: 14px 14px 14px 2.75rem;
}

.ProfileBrowserEditSocialMedia:focus {
  box-shadow: none;
  outline: none;
}

.ProfileBrowserEditSocialMediaDivider {
  border-top: 1px solid #b9b9b9;
  width: 100%;
  margin: 0px;
}

.ProfileBrowserEditBio {
  box-sizing: border-box;
  color: #000;
  /* clamp values are dependent on ButtonProfile styling */
  width: clamp(314px, 24vw, 414px);
  display: flex;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  border: 0px solid black;
  border-radius: 12px;
  background-color: #ededed;
}

.ProfileBrowserEditBio:focus {
  box-shadow: none;
  outline: none;
}

.ProfileBrowserFollow {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-weight: bold;
  font-size: 22px;
  color: #929292;
}

.ProfileBrowserEventListContainer {
  padding: 1em;
  margin-bottom: 10em;
}

.ProfileBrowserHeaderContainer {
  display: flex;
  flex-direction: column;
  box-shadow: #000;
}

.FollowOverlayHeader {
  color: #000;
  font-size: 16px;
  font-weight: 600;
}

.FollowOverlayList {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 24px;
}

.ProfileBrowserPageContainer {
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: center;
  display: flex;
}

.Checkout {
  padding-bottom: 15%;
}

.ProfileWrapper {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ProfileHeaderDesktop {
  align-items: center;
  width: 100%;
}

.ButtonProfile {
  min-width: 150px;
  width: calc((100vw - (76vw + 14px)) / 2);
  max-width: 200px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  font-weight: 600;
  font-size: 14px;
  background-color: #cecece;
  color: #727272;
  text-decoration: none;
}

.ButtonProfileMessage {
  height: 41px;
  min-width: 150px;
  width: calc((100vw - (76vw + 14px)) / 2);
  max-width: 200px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  background-color: #ededed;
  color: black;
  text-decoration: none;
  cursor: pointer;
  gap: 5px;
}

.ButtonProfileText {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 7px;
}

.FollowFormDesktop {
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(244, 245, 246, 0.8);
  backdrop-filter: blur(30px);
}

.FollowFormMobile {
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(white, 10%, #b9b9b9, #f7f7f7, #b9b9b9);
}

.FollowFormContentDesktop {
  width: 550px;
  overflow-y: scroll;
}

.FollowFormContentMobile {
  width: 90%;
  overflow: hidden;
}

.FollowHeader {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 97%;
  padding-top: 24px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.CreatorModeLeftPanel {
  align-items: flex-start;
  position: sticky;
  z-index: 2;
}

.CreatorModeMenuItemContainer {
  cursor: pointer;
  padding-inline: 12px;
  border-radius: 8px;
  height: 44px;
  gap: 7px;
}

.CreatorModeRightPanel {
  width: 86vw;
  margin-left: 14vw;
  height: 100vh;
  background-color: white;
}

.CreatorModeRightPanelPlusDropdown {
  z-index: 99;
  position: absolute;
  right: 72px;
  top: 72px;
  padding: 14px;
  width: 150px;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0px 4px 24px 0px #1e1e371a;
}

.CreatorModeRightPanelPlusDropdownDisabled {
  color: #929292;
  font-size: 14px;
  cursor: not-allowed;
}

.HeaderProfilePicDropdown {
  z-index: 99;
  position: absolute;
  width: 249px;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0px 4px 24px 0px #1e1e371a;
}

.ProfilePageCreatorSidebarCard {
  padding: 14px;
  border-radius: 14px;
  margin: 14px;
  transition: 0.1s ease;
  overflow: hidden;
}

.ProfilePageCreatorSidebarCardSubtext {
  color: #b9b9b9;
  font-size: 12px;
  font-weight: 400;
  padding-right: 14px;
}

.ProfilePanelWhiteButton {
  border-radius: 8px;
  border: 1px solid black;
  padding-block: 10px;
  width: 100%;
  display: grid;
  place-items: center;
  cursor: pointer;
}

.ProfilePanelBlackButton {
  border-radius: 8px;
  background-color: black;
  padding-block: 10px;
  width: 100%;
  display: grid;
  place-items: center;
  cursor: pointer;
}

.MorePanelContainer {
  background-color: white;
  height: 100%;
  position: absolute;
  width: 300px;
  top: 0px;
  box-sizing: border-box;
  padding: 14px;
  z-index: 5;
}

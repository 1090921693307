.OrganizerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 5px;
  padding-bottom: 5px;
}

.OrganizerInfo {
  display: flex;
  flex-direction: row;
}

.OrganizerFollowBtn {
  border-radius: 8px;
  padding: 5px;
}

.OrganizerProfilePic {
  width: 2.8em;
  height: 2.8em;
  border-radius: 1.4em;
  object-fit: cover;
}

.Creator {
  font-size: 14px;
  margin-right: 1em;
}

.FollowingText {
  color: gray;
  font-size: 0.7em;
}

.NameContainer {
  margin-left: 1em;
  margin-bottom: 0.3em;
}

.OrganizerFullName {
  font-weight: 500;
  font-size: 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.Hosts {
  display: flex;
  text-overflow: "ellipsis";
  overflow: hidden;
  white-space: nowrap;
  line-height: 1.3em;
}

/** SubscriptionDetails.tsx **/
.SubscriptionDetailsBillingTitle {
  color: #000;
  padding-bottom: 7px;
  font-size: 20px;
  font-weight: 600;
}

.SubscriptionDetailsContainer {
  background-color: #000;
  display: flex;
  flex-direction: column;
  height: 110vh;
}

.SubscriptionDetailsLoadingContainer {
  background-color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 10em;
}

.SubscriptionDetailsAccountTitle {
  color: #fff;
  font-size: 24px;
  margin-top: 10px;
  margin-bottom: 0;
}

.SubscriptionDetailsFrozen {
  color: #fe296c;
  font-size: 24px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
}

.SubscriptionDetailsPrice {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: -10px;
}

.SubscriptionDetailsUsername {
  color: #fff;
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.SubscriptionProfilePic {
  display: block;
  height: 5em;
  width: 5em;
  border-radius: 2.5em;
}
.SubscriptionProfilePicInitials {
  display: block;
  height: 5em;
  width: 5em;
  border-radius: 2.5em;
  background-color: rgb(17, 184, 255);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 20px;
}

/** SubscriptionCheckout.tsx **/
.SubscriptionCheckoutPaymentSheet {
  background-color: #fff;
  pointer-events: auto;
}

/** SubscriptionCheckoutForm.tsx **/
.SubscriptionCreditCard {
  text-align: center;
  color: #11b8ff;
  margin-bottom: 0em;
}

/* For making the input tag have no styling */
#style-input {
  all: unset;
}

#style-input input:focus {
  all: unset;
}

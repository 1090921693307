.InputFormContainerDesktop {
  display: flex;
  flex-direction: column;
  width: 410px;
  margin: auto;
}

.NameAndNumber {
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.TicketOptionsRedeem {
  background-color: #f4f5f6;
  padding-right: 0.5em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.TicketOptions {
  padding-left: 0px;
}

.YourInfoContainer {
  padding: 14px;
  background-color: white;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
}

.HostQuestionsContainer {
  background-color: white;
  padding: 14px;
  border-radius: 12px;
  align-items: center;
  align-self: center;
  margin-top: 12px;
}

.HostQuestionsContainerDesktop {
  background-color: white;
  padding: 14px;
  border-radius: 12px;
  align-items: center;
  margin-top: 12px;
}

.TicketContainer {
  background-color: white;
  padding: 14px;
  border-radius: 12px;
  align-items: center;
  align-self: center;
  margin-top: 12px;
}

.TicketContainerDesktop {
  background-color: white;
  padding: 14px;
  border-radius: 12px;
  align-items: center;
  margin-top: 12px;
}

.TicketIssueContainer {
  background-color: white;
  border-radius: 12px;
  padding: 14px;
  margin-top: 12px;
}

.TicketIssueContainerDesktop {
  background-color: white;
  border-radius: 12px;
  padding: 14px;
  margin-top: 12px;
}

#LightName:-webkit-autofill {
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 0px 9999px #f4f5f6 !important;
}

#LightVerifiedName:-webkit-autofill {
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 0px 9999px #fff !important;
}

#LightNumber:-webkit-autofill {
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 0px 9999px #f4f5f6 !important;
}

#LightVerifyingNumber:-webkit-autofill {
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 0px 9999px #f4f5f6 !important;
}

#LightVerifiedNumber:-webkit-autofill {
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 0px 9999px #fff !important;
}

#DarkName:-webkit-autofill {
  border-radius: 8px;
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: inset 0 0 0px 9999px #313135 !important;
}

#DarkVerifiedName:-webkit-autofill {
  border-radius: 8px;
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: inset 0 0 0px 9999px #1c1c1d !important;
}

#DarkNumber:-webkit-autofill {
  border-radius: 8px;
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: inset 0 0 0px 9999px #313135 !important;
}

#DarkVerifyingNumber:-webkit-autofill {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: inset 0 0 0px 9999px #313135 !important;
}

#DarkVerifyingNumber:-webkit-autofill:hover {
  border-radius: 8px;
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: inset 0 0 0px 9999px #313135 !important;
}

#DarkVerifiedNumber:-webkit-autofill {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: inset 0 0 0px 9999px #1c1c1d !important;
}

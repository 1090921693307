/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

.BuyBanner {
  border-radius: 12px 12px 0 0;
  padding: 14px;
  border-top: 1px solid #b9b9b9;
  border-left: 1px solid #b9b9b9;
  border-right: 1px solid #b9b9b9;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: sticky;
  bottom: 0;
  z-index: 2;
}

.BuyBannerDesktop {
  padding: 14px;
  border-radius: 12px;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.BuyBannerContainer {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
}

.BuyOpenButton {
  width: 40%;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border-radius: 0.5em;
}

.BuyOpenButtonText {
  margin: 0em;
  text-align: center;
  color: white;
  font-weight: 700;
}

.BuyFullName {
  font-weight: 600;
  font-size: 1.2em;
  color: black;
}

.BuyUsername {
  font-size: 0.8em;
  font-weight: 500;
  color: #929292;
}

.agree-to-terms {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 8px;
  align-items: start;
  /* margin-inline: 14px; */
}

.agree-to-terms label {
  line-height: 1.2;
  font-size: 13px;
  font-weight: 500;
  color: #929292;
}

.agree-to-terms label p {
  margin: 0;
}

.agree-to-terms label a {
  text-decoration: none;
}

.EventQRContainer {
  display: flex;
  flex: 1;
  height: 100vh;
}

.EventQRCodeImage {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-inline: 14px;
}

.EventQRBody {
  background-color: white;
  border-radius: 14px;
  box-shadow: 1px 2px 5px 2px #b9b9b9;
  padding-block: 14px;
}

.OrganizersContainer {
  padding-left: 14px;
  padding-right: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.OrganizersBodyDescription {
  margin-left: 2.2em;
  margin-top: 0.5em;
  font-size: 0.9em;
  font-weight: 400;
  color: #3d3d3d;
}

.OrganizersEmptyStateText {
  display: flex;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 60px;
  padding-right: 60px;
}

.OrganizersFinalHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.OrganizersTitle {
  font-size: 16px;
  font-weight: 500;
}

.GuestList {
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(244, 245, 246, 0.4);
  backdrop-filter: blur(30px);
}

.GuestListWrapperDesktop {
  width: 400px;
  border-radius: 12px;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  overflow: hidden;
}

.GuestContent {
  height: 65vh;
  overflow-y: scroll;
  margin-inline: 14px;
  margin-top: 7px;
}

.GuestContentDesktop {
  height: 500px;
  overflow-y: scroll;
  margin-inline: 14px;
  margin-top: 7px;
}

.GuestListModalView {
  position: fixed !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
}

.GuestListWrapperMobile {
  position: absolute;
  top: 10%;
  right: 0;
  margin-inline: 14px;
  left: 0;
  background-color: white;
  border-radius: 12px;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  overflow: hidden;
}

.GuestLine {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.GuestInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.GuestPic {
  width: 35px;
  height: 35px;
  border-radius: 100px;
  object-fit: cover;
}

.GuestListTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 97%;
  padding-top: 24px;
  margin-bottom: 24px;
}

.GuestListTitleText {
  font-size: 1.4em;
  font-weight: 600;
}

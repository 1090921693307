.EmptyUserStateContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.EmptyUserStateCircle {
  width: 35px;
  height: 35px;
  background-color: #ededed;
  border-radius: 100px;
}

.EmptyUserStateRects {
  display: flex;
  flex-direction: column;
}

.EmptyUserStateRect1 {
  width: 62px;
  height: 16px;
  background-color: #ededed;
}

.EmptyUserStateRect2 {
  width: 152px;
  height: 16px;
  background-color: #ededed;
}

.EmptyUserStateLabel {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.BlurFilter {
  filter: blur(4px);
}

.MassTextsModalAttachmentPill {
  background-color: #f4f5f6;
  min-width: 53px;
  height: 37px;
  border-radius: 40px;
  display: flex;
  flex-direction: row;
  gap: 2px;
  justify-content: center;
  align-items: center;
  transition: 0.1s ease;
}

.MassTextsTextArea {
  padding: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  width: 100%;
  background-color: transparent;
  border: none;
  resize: none;
  caret-color: #000;
}

.MassTextsTextArea::placeholder {
  color: #b9b9b9;
}

.MassTextsTextArea:focus {
  outline: none;
}

.SelectedRecipientsBanner {
  background-color: #f4f5f6;
  padding-inline: 14px;
  padding-block: 7px;
  border-radius: 6px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.CreatorChatSendButton {
  width: 32px;
  height: 32px;
  border-radius: 100px;
  display: grid;
  align-items: center;
  justify-content: center;
}

.CreatorChatAttachmentButton {
  margin-right: 10px;
  margin-left: 14px;
  width: 46px;
  height: 46px;
  background-color: #fff;
  cursor: pointer;
  border: 0.5px solid #b9b9b9;
  border-radius: 100px;
  display: grid;
  align-items: center;
  justify-content: center;
  transition: 0.2s ease;
}

.CreatorChatAttachmentButton:hover {
  background-color: #d9d9d9;
}

.Banner {
  margin-top: 1em;
  padding-block: 10px;
  padding-inline: 15px;
  border-radius: 0.5em;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.LeftContainer {
  cursor: pointer;
}

.OpenButton {
  background-color: #007aff;
  width: 6.5em;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  border-radius: 0.5em;
  padding: 0.3em;
  cursor: pointer;
}

.OpenButtonText {
  margin: 0.25em;
  text-align: center;
  color: white;
  font-weight: 600;
  font-size: 0.9em;
  cursor: pointer;
}

.Username {
  margin-top: 0.1em;
  color: rgba(60, 60, 67, 0.6);
  font-size: 0.9em;
  cursor: pointer;
}

.BannerFullName {
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.BannerFullName p {
  cursor: pointer;
}

/*
 * Size naming convention:
 * - First size word refers to font size (small, medium, etc.)
 * - Second size word refers to font weight (regular, medium, etc.)
 */

.sectionTitle {
  font-weight: 500;
}

.bodyMedium {
  font-size: 14px;
  font-weight: 500;
}

.bodySmallMedium {
  font-size: 12px;
  font-weight: 500;
}

.bodySmallRegular {
  font-size: 12px;
  font-weight: 400;
}

.bodySubtext {
  font-size: 14px;
  color: #929292;
  font-weight: 400;
}

.smallBodySubtext {
  font-size: 12px;
  color: #929292;
}

table {
  border-collapse: separate;
  border-spacing: 0;
}
table tr th,
table tr td {
  border-right: 1px solid #929292;
  border-bottom: 1px solid #929292;
}

table tr th:first-child,
table tr td:first-child {
  border-left: 1px solid #929292;
}
table tr th {
  background-color: #fafafa;
  border-top: solid 1px #929292;
  font-weight: 500;
}

/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 14px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 14px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 14px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 14px;
}

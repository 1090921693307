.AlignedColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.AlignedColumnSpaced {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.AlignedRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.AlignedRowRight {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.AlignedRowSelect {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.AlignedRowSpaced {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.AlignedRowSpacedSelect {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.AlignedRowBottomSelect {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  cursor: pointer;
}

.AlignedTopRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-inline: 14px;
  padding-block: 14px;
}

.AlignedTopRowDesktop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px;
}

.Centering {
  display: flex;
  justify-content: center;
  align-items: center;
}

.CenteringSelect {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.ColumnCentering {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ColumnNormal {
  display: flex;
  flex-direction: column;
}

.ColumnNormalSpaced {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ColumnNormalSelect {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.ColumnCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.CreateEventButton {
  border-radius: 100px;
  padding: 7px;
  padding-inline: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  font-size: 15px;
  transition: 0.1s ease;
  font-weight: 500;
  cursor: pointer;
}

.RowNormal {
  display: flex;
  flex-direction: row;
}

.RowNormalSpaced {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.TabNameRowActive {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-bottom: 2px solid #000;
}

.TabNameRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-bottom: 2px solid #f4f5f6;
}

.TabName {
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.TextOverflowEllipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
}

.TextOverflowWrap {
  overflow-wrap: break-word;
  display: block;
}

.OneLineText {
  max-height: 2.4em;
  overflow: hidden;
  max-width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.TwoLineText {
  color: #929292;
  font-size: 14px;
  max-height: 2.4em;
  overflow: hidden;
  max-width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.HideScrollbar::-webkit-scrollbar {
  display: none;
}

.RestrictContainerWidth {
  max-width: 100%;
  word-wrap: break-word;
  overflow-x: hidden;
}

/** Use this on a div to prevent all scrolling for anything within the div */
.PreventAllScrolling {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/** Use this on all outer divs if we want an inner div section to be scrollable */
.PreventScroll {
  height: 100%;
  overflow-y: hidden;
  flex-grow: 1;
}

/** Used in combination with PreventScroll, add to the inner div that we want to be scrollable */
.AllowScroll {
  height: 100%;
  overflow-y: auto;
  flex-grow: 1;
}

.WebApp {
  background-color: #f4f5f6;
  height: 100%;
}

.WebContainer {
  padding-bottom: 5em;
}

.WebColumnContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.WebImageContainer {
  width: calc((100vw - (36vw + 14px)) * (400 / 880));
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.WebInfo {
  width: calc((100vw - (36vw + 14px)) * (480 / 880));
  margin-left: 14px;
}

.WebImage {
  object-fit: cover;
  width: 100%;
  height: calc((100vw - (36vw + 14px)) * (400 / 880));
  border-radius: 14px;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.ExpandedPhotoDesktop {
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(244, 245, 246, 0.8);
  backdrop-filter: blur(30px);
}

.ExpandedPhotoHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 97%;
  padding-top: 24px;
  margin-bottom: 24px;
}

.ExpandedPhoto {
  height: 85vh;
  border-radius: 12px;
}

.ExpandedPhotoMobile {
  width: 100%;
  border-radius: 8px;
}

.ExpandedPhotoMobileContainer {
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  flex-direction: column;
  align-items: center;
  background-color: rgba(244, 245, 246, 0.8);
  backdrop-filter: blur(30px);
}

.ExpandedPhotoShareButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  padding-inline: 8px;
  padding-block: 6px;
  border-radius: 100px;
}

.MassTextsSectionContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 14px;
  background-color: #f4f5f6;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
}

.MassTextsAttachmentButton {
  width: 36px;
  height: 36px;
  border-radius: 40px;
  display: grid;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s ease;
}

.MassTextsAttachmentButton:hover {
  background-color: #dedede;
}

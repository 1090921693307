.ConfirmFollowContainer {
  margin-top: 2em;
  margin-bottom: 0.5em;
  padding: 2em;
  background-color: #22222d;
  border-radius: 1em;
}

.ConfirmFollowRow {
  margin-top: -0.5em;
  margin-bottom: 0.5em;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.ConfirmFollowText {
  font-size: 1em;
  font-weight: 500;
  color: #fff;
}

.ConfirmFollowBtn {
  width: 100%;
  height: 3em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 0.6em;
  border-color: #11b8ff;
  background-color: #11b8ff;
  margin-bottom: 0.5em;
}

.ProfileContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: -0.5em;
}

.ProfileInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ProfilePic {
  width: 3em;
  height: 3em;
  border-radius: 1.5em;
  margin-bottom: 0.3em;
}

.Creator {
  font-size: 14px;
  margin-right: 1em;
}

.NameContainer {
  margin-left: 1em;
  margin-bottom: 0.3em;
}

.FullNameItem {
  margin-left: 0.5em;
  margin-bottom: 0;
  font-weight: 500;
}

.FullNamePostItem {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 16px;
}

.FullNameCohost {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Hosts {
  display: flex;
  text-overflow: "ellipsis";
  overflow: hidden;
  white-space: nowrap;
  line-height: 1.3em;
}

.UsernameItem {
  margin-top: 0;
  color: rgba(60, 60, 67, 0.6);
  font-size: 0.9em;
}

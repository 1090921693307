hr {
  margin-block: 14px;
  margin-inline: -14px;
  border: 0;
  border-top: 1px solid #ededed;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0 !important;
}

.Wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.AboutSubtitle {
  font-size: 16px;
  font-weight: 600;
}

.AlignedRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Capacity {
  background-color: rgba(234, 234, 241, 0.4);
  width: auto;
  height: 1em;
  margin-top: 0.5em;
  flex-direction: row;
  display: flex;
  padding: 0.5em;
  padding-top: 0.3em;
  padding-bottom: 0.4em;
  border-radius: 0.5em;
}

.ConfirmContainer {
  margin-top: 12px;
  margin-bottom: 12px;
  padding: 1em;
  background-color: white;
  border-radius: 0.5em;
}

.ConfirmTextHeader {
  font-size: 16px;
  font-weight: 600;
}

.Date {
  margin-left: 0em;
  margin-bottom: 0em;
}

.FullEventHeaderSectionTitle {
  font-size: 1em;
  font-weight: 600;
  margin: 0;
}

.FullEventHeaderSectionSubtext {
  margin: 0;
  color: #929292;
  font-size: 14px;
}

.Description {
  margin-top: 0em;
  margin-bottom: 0.3em;
  font-size: 1.7em;
  font-weight: bold;
  color: black;
}

.AboutContent {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.FinalHeaderDesktop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  height: 24px;
}

.FinalTitle {
  font-size: 24px;
  font-weight: 500;
}

.FinalTitleText {
  font-size: 18px;
  font-weight: 600;
}

.FinalErrorContainer {
  border: 1px solid;
  border-radius: 12px;
  padding-block: 14px;
  margin-top: 24px;
}

.FinalErrorTitle {
  color: #f92d48;
  font-weight: 500;
}

.FinalSubText {
  font-size: 14px;
  color: #929292;
}

.FinalEventPageText {
  font-weight: 500;
  margin-bottom: 7px;
}

.FullNameCohost {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.FullNamePost {
  margin-bottom: 0;
  font-size: 0.8em;
  font-weight: 500;
}

.Going {
  background-color: rgba(234, 234, 241, 0.4);
  width: auto;
  height: 1em;
  flex-direction: row;
  display: flex;
  margin-top: 0.5em;
  padding: 0.5em;
  padding-top: 0.3em;
  padding-bottom: 0.5em;
  border-radius: 1em;
}

.Guests {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
}

.Hosts {
  display: flex;
  text-overflow: "ellipsis";
  overflow: hidden;
  white-space: nowrap;
  line-height: 1.3em;
}

.HostCohostListLink {
  color: black;
  text-decoration: none;
}

.IconSquare {
  min-width: 18px;
  min-height: 18px;
  max-width: 18px;
  max-height: 18px;
  border-radius: 100px;
  object-fit: cover;
}

.ImageEvent {
  display: flex;
  align-self: center;
  border-radius: 8px;
  overflow: hidden;
  object-fit: contain;
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1;
}

.InfoContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 14px;
}

.InfoContainerIcon {
  min-height: 40px;
  max-height: 40px;
  min-width: 40px;
  max-width: 40px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Link {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  color: #007aff;
  margin-top: 8px;
}

.LoadingText {
  display: flex;
  align-self: center;
  justify-content: center;
  margin-top: 0em;
}

.MinTicketText {
  margin-top: 0;
  font-size: 12px;
  color: #929292;
}

.Page {
  background-color: white;
  margin-top: 14px;
  margin-inline: 14px;
  border-radius: 8px;
  box-shadow: rgba(149, 157, 165, 0.1) 0px 8px 24px;
}

.Container {
  padding: 14px;
}

.PageDesktop {
  background-color: white;
  border-radius: 12px;
  margin-bottom: 14px;
}

.PostContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.PostText {
  margin: 0;
  font-size: 0.7em;
}

.PostNameContainer {
  width: 70%;
  margin-left: 1em;
  background-color: white;
  padding: 0.7em;
  padding-top: 0;
  border-radius: 0.5em;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
}

.PostProfilePic {
  width: 2.5em;
  height: 2.5em;
  border-radius: 2.5em;
}

.PromoCodeApplyView {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.PromoCodeApplyText {
  color: #007aff;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
}

.PromoCodeInput {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 14px;
}

.PromoCodeInputView {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 14px;
  margin-left: -14px;
}

.PurchaseText {
  color: #929292;
}

.QuestionDescription {
  margin: 0;
  color: #929292;
}

.RSVPButton {
  width: 100%;
}

.useCreditCard {
  text-align: center;
  color: #11b8ff;
  margin-bottom: 0em;
}

.SupportContainer {
  margin-top: 14px;
  padding: 14px;
  flex-direction: row;
}

.SupportText {
  text-align: center;
  font-size: 16px;
  line-height: 1.4em;
  font-weight: 600;
}

.SupportLink {
  font-size: 1em;
  line-height: 1.4em;
  font-weight: 500;
  display: inline;
  color: #11b8ff;
}

.TicketsExceedCapacity {
  font-size: 12px;
  margin-bottom: 0;
}

.TicketStrike {
  margin-left: 0.3em;
  color: gray;
  position: relative;
  text-decoration: none;
}

.TicketStrike::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  background: gray;
  transform: rotate(-7deg);
}

.TicketSubDetails {
  display: inline;
  font-size: 14px;
  color: #929292;
}

.TicketTotalAmount {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
}

.TicketTotalOptions {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
}

.TitleDesktop {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 14px !important;
}

.Title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 8px !important;
}

.TimeAgoText {
  margin-top: 0;
  color: rgba(60, 60, 67, 0.6);
  font-size: 0.6em;
}

.Visibility {
  font-size: 0.9em;
  font-weight: 500;
  color: #979797;
  margin-top: -0.75em;
}

.checkoutElement {
  margin-top: 15px;
}

.applePayElement {
  margin-bottom: 15px;
}

.ResponseHeader {
  flex-direction: row;
  display: flex;
  gap: 7px;
  align-items: flex-end;
}

.textFieldDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 14px;
}

.ReadOrHideClosed {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin-top: -48px;
  padding-top: 60px;
  position: relative;
  z-index: 1;
}

.ReadOrHideOpened {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  gap: 5px;
  position: relative;
  z-index: 1;
}

.ReadOrHideText {
  font-size: 14px;
  font-weight: 600;
  color: black;
  cursor: pointer;
}

.TicketPrices {
  font-size: 14px;
  padding: 14px;
  border-radius: 8px;
  font-weight: 600;
}

.TicketPricesContainer {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.GuestListPicture {
  width: 100%;
}

.FullEventFeaturedEventsContainer {
  margin-block: 60px;
  margin-inline: 18vw;
}

.FullEventFeaturedEventsHeader {
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 14px;
}

.HostCohostsHeader {
  margin: 0;
  color: #929292;
  font-size: 13px;
  font-weight: 400;
}

.CreatorTextManualSubtext {
  color: #929292;
  font-size: 13px;
  width: 85%;
}

.FullEventFinalWhiteButton {
  background-color: #f4f5f6;
  padding-block: 14px;
  text-align: center;
  border-radius: 12px;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
}

.OverlayWrapperDesktop {
  width: 400px;
  border-radius: 12px;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  overflow: hidden;
}

.OverlayModalView {
  position: fixed !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
}

.OverlayWrapperMobile {
  position: absolute;
  top: 8%;
  right: 0;
  margin-inline: 14px;
  left: 0;
  background-color: white;
  border-radius: 12px;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  overflow: hidden;
}

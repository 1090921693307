.EventDashboardTopContent {
  width: 60%;
  justify-content: center;
  margin: 0 auto;
}

.EventDashboardEventPhoto {
  width: 84px;
  height: 84px;
  border-radius: 8px;
}

.EventDashboardEventDescription {
  display: flex;
  flex-direction: column;
  gap: 7px;
  justify-content: center;
  margin-left: 20px;
}

.EventDashboardEventTitle {
  font-size: 24px;
  font-weight: 500;
}

.EventDashboardEventDetails {
  color: #929292;
  font-size: 14px;
}

.EventDashboardEditButton {
  height: 35px;
  width: 129px;
  gap: 5px;
  border-radius: 8px;
  background-color: #eeeef0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.EventTabNameRowActive {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 5px;
  margin-right: 40px;
  border-bottom: 2.5px solid #000;
  cursor: pointer;
}

.EventTabNameRowDisabled {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 7px;
  margin-right: 40px;
  cursor: auto;
  color: #ededed;
}

.EventTabNameRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 7px;
  margin-right: 40px;
  cursor: pointer;
  color: #b9b9b9;
}

.EventDashboardLeftContainer {
  padding-block: 14px;
  background-color: white;
  border-radius: 8px;
  margin-bottom: 14px;
}

.EventDashboardRightContainer {
  padding-block: 14px;
  border-radius: 8px;
  background-color: white;
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  flex-grow: 1;
}

.EventDashboardContentHeader {
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 4px;
}

.EventDashboardContentDescription {
  color: #b9b9b9;
  font-size: 12px;
}

.EventDashboardColumnInfo {
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding-inline: 14px;
}

.EventDashboardBlueText {
  color: #007aff;
  font-size: 24px;
}

.EventDashboardGrayContainer {
  margin-inline: 14px;
  padding-block: 14px;
  border-radius: 8px;
  background-color: #f4f5f6;
}

.EventDashboardGrayContainerHeader {
  margin-inline: 14px;
  font-size: 14px;
  font-weight: 600;
}

.EventDashboardWhiteContainer {
  padding: 14px;
  margin-inline: 14px;
  background-color: white;
  border-radius: 8px;
}

.EventDashboardGuestName {
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.EventDashboardGuestDescription {
  color: #666666;
  font-size: 12px;
  cursor: pointer;
}

.EventDashboardGuestCheckedIn {
  width: 84px;
  padding-block: 7px;
  border-radius: 8px;
  background-color: #ededed;
  color: #666666;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.EventDashboardGuestCheckIn {
  width: 84px;
  padding-block: 7px;
  border-radius: 8px;
  background-color: #007aff;
  color: white;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.EventDashboardPromoterContainer {
  display: flex;
  padding-left: 14px;
  padding-right: 14px;
  background-color: white;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
}

.EventDashboardModal {
  padding-block: 14px;
  position: absolute;
  /* top: calc(100% - 100px); */
  /* left: 0px; */
  /* transform: translateY(-100px); */
  /* minWidth: "200px", */
  /* margin-top: 15%;
  right: 28%; */
  /* margin-top: 210px; */
  /* right: 402px; */
  z-index: 5;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0px 4px 24px 0px rgba(30, 30, 55, 0.1);
}

.EventDashboardModalGuestName {
  font-size: 14px;
  margin-bottom: 4px;
}

.EventDashboardModalButton {
  padding-inline: 14px;
  color: #b9b9b9;
  font-size: 14px;
}

.EventDashboardContainerSpaceBetween {
  display: flex;
  padding: 14px;
  background-color: #f4f5f6;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}

.EventDashboardDarkGrayContainer {
  display: flex;
  padding: 14px;
  border-radius: 8px;
  background-color: #929292;
  color: white;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  align-items: center;
}

.ShareEventModalDropdown {
  width: 93.5%;
  position: absolute;
  z-index: 99;
  background-color: white;
  border-radius: 12px;
  margin-top: 5px;
  padding-block: 14px;
  box-shadow: -3px 7px 25px 1px rgba(0, 0, 0, 0.3);
}

.ShareEventModalDropdownItem {
  display: flex;
  justify-content: space-between;
  padding-inline: 14px;
  cursor: pointer;
}

.ShareEventModalLinks {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.ShareEventModalLinkIcon {
  display: flex;
  background-color: #fff;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  height: 53px;
  width: 53px;
  cursor: pointer;
}

.ShareEventModalTrackingLink {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-block: 85px;
  padding-inline: 72px;
}

.modalHeader {
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.modalHeaderDescription {
  font-weight: 400;
  color: #929292;
  font-size: 13px;
  padding-right: 20px;
}

.modalWordCount {
  font-weight: 400;
  font-size: 14px;
  color: #929292;
}

.dropdownMenuItem {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.EventDashboardTextingPanelOverview {
  display: flex;
  flex-direction: row;
  gap: 14px;
  margin-top: 14px;
}

.TextingPanelMassTextHeaderContainer {
  background-color: #f4f5f6;
  padding: 14px;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr 1fr 1.4fr 0.8fr 2fr;
  gap: 48px;
  margin-top: 14px;
}

.TextingPanelSearchContainer {
  box-sizing: border-box;
  background-color: #f4f5f6;
  border-radius: 8px;
  width: 100%;
  gap: 14px;
}

.TextingPanelIconAndInput {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  padding: 12px;
}

.TextingPanelInput {
  padding: 0;
  font-size: 15px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background-color: transparent;
  border: 0px solid black;
  resize: none;
}

.TextingPanelInput::placeholder {
  color: #b9b9b9;
}

.TextsOptionButtonContainer {
  border: 2px solid;
  padding-inline: 14px;
  padding-block: 10px;
  border-radius: 8px;
  width: 10vw;
  cursor: pointer;
}

.RecipientsOptionButtonContainer {
  border: 2px solid;
  padding-inline: 14px;
  padding-block: 10px;
  border-radius: 8px;
  width: 100%;
  cursor: pointer;
}

.OverflowTextSubtitle {
  color: #929292;
  font-size: 14px;
  font-weight: 400;
  padding-top: 4px;
}

.ConversationsPanelLeftContainer {
  width: 413px;
}

.ConversationsPanelRightContainer {
  flex: 1;
  border-left: 0.5px solid #ededed;
  background-color: #fafafa;
}

.ConversationOptionButtonContainer {
  margin: 18px;
  margin-left: 0px;
}

.ConversationSidebarItem {
  cursor: pointer;
  padding-block: 7px;
  padding-inline: 14px;
  border-radius: 12px;
}

.ConversationSidebarItem * {
  cursor: pointer;
}

.ConversationSidebarItem:hover {
  background-color: #f4f5f6;
}

.ConversationsMainViewHeader {
  height: 59px;
  padding-inline: 14px;
  gap: 14px;
  background-color: #ffffff;
  border-bottom: 0.5px solid #ededed;
}

.ConversationsInputContainer {
  box-sizing: border-box;
  border: 0.5px solid #b9b9b9;
  background-color: #fff;
  border-radius: 20px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  margin-right: 14px;
  width: calc(100% - 70px);
}

.ConversationsInput {
  padding: 14px;
  font-size: 15px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  width: 100%;
  background-color: transparent;
  border: 0px solid black;
  resize: none;
}

.ConversationsInput:focus {
  outline: none;
}

.LoadMoreConversationsButton {
  display: inline-block;
  cursor: pointer;
  align-self: center;
  background-color: #f4f5f6;
  border-radius: 8px;
  padding: 8px;
  padding-inline: 12px;
  margin: 20px;
  margin-bottom: 0px;
  height: 100%;
}

.MessagesCenteredDate {
  display: inline-block;
  align-self: center;
  border-radius: 8px;
  margin: 20px;
  color: #b9b9b9;
  font-size: 14px;
}

.ConversationsCenteredProfilePic {
  width: 100%;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  place-items: center;
  gap: 14px;
}

.MessagesContainer {
  display: flex;
  flex-direction: column;
  gap: 7px;
  align-items: flex-end;
  justify-content: flex-end;
  margin-inline: 20px;
  margin-top: 10px;
}

.ConversationsBoxContainer {
  background-color: #f4f5f6;
  padding: 14px;
  border-radius: 8px;
  width: 100%;
  height: 100px;
  cursor: pointer;
  transition: 0.1s ease;
  border: 2px solid #f4f5f6;
}

.ConversationsBoxContainer:hover {
  border: 2px solid #007aff;
}

.ConversationsBoxContainer * {
  cursor: pointer;
}

.UnreadIndicator {
  width: 8px;
  height: 8px;
  background-color: #fe296c;
  border-radius: 64px;
}

.FloatingActionButton {
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 24px;
  right: 24px;
  box-shadow: 0px 24px 62px 2px #00000024;
  border-radius: 100px;
  display: grid;
  align-items: center;
  justify-content: center;
  border: 0.5px solid #b9b9b9;
  background-color: white;
  cursor: pointer;
  transition: 0.1s ease;
}

.FloatingActionButton:hover {
  background-color: #b5620b;
  border: 0.5px solid #b5620b;
  filter: invert(1);
}

.HelpContainer {
  padding: 10px 14px;
  background-color: #f4f5f6;
  font-size: 14px;
  font-weight: 600;
  border-radius: 100px;
  cursor: pointer;
}

.PercentRedAlertInner {
  display: flex;
  align-items: center;
  gap: 18px;
}

.PercentRedAlertSubTextWrapper {
  display: flex;
  align-items: center;
}

.PercentTabText {
  font-size: 14px;
  font-weight: 500;
  gap: 7px;
}

.PercentModalText {
  font-weight: 500;
  font-size: 16px;
  color: black;
  margin-top: 7px;
}

hr {
  border: 0.75px solid #eaeaf1;
}

h2,
p {
  cursor: default;
}

.InfoRow {
  align-items: center;
}

.IconDateSquare {
  background-color: #e9f9ff;
}

.IconDay {
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  margin: 0;
  margin-top: 0.25em;
}

.IconMonth {
  text-align: center;
  margin: 0;
  font-size: 14px;
  color: #11b8ff;
  font-weight: 600;
}

.Community {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.CommunityBody {
  width: 90%;
  background-color: white;
  border-radius: 1em;
  padding: 5%;
  margin-bottom: 20%;
  align-items: center;
  justify-content: center;
}

.CommunityContainer {
  flex: 1;
  background-color: "#fff";
}

.CommunityHeader {
  width: 85%;
  background-color: white;
  padding: 5%;
  padding-bottom: 0%;
  align-items: center;
  justify-content: center;
}

.CommunityImage {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 7em;
  width: 7em;
  border-radius: 4em;
  object-fit: cover;
}

.CommunityMembers {
  padding-left: 0em;
}

.CommunityMembersItem {
  border-bottom: 1px solid lightgray;
}

.CommunityMessage {
  text-align: center;
  color: gray;
}

.CommunityTitle {
  text-align: center;
}

.CrowdfundingText {
  color: black;
  font-weight: 600;
  font-size: 1.3em;
  width: auto;
}

.CheckoutTitle {
  font-weight: 600;
  margin-bottom: 1em;
  text-align: center;
}

.CheckoutBreakdown {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid lightgray;
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-top: 0.3em;
  margin-top: 0.3em;
  margin-bottom: 0.3em;
}

.CloseButton {
  height: 1.25em;
  width: 1.25em;
  padding-right: 14px;
}

.Extra {
  height: 3em;
}

.Hosts {
  display: flex;
  text-overflow: "ellipsis";
  overflow: hidden;
  white-space: nowrap;
  line-height: 1.3em;
}

.Image {
  object-fit: cover;
  object-position: 50% top;
  width: 100%;
  height: 100%;
  margin-top: 0;
}

.Link {
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  color: #007aff;
}

.BrowserLink {
  width: 75%;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  color: #11b8ff;
}

.ErrorOrMaintenanceContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  min-height: 100vh;
  width: 100vw;
}

.LoadingScreen {
  height: calc(100vh - 72px);
  width: 100vw;
  display: flex;
  place-items: center;
  justify-content: center;
}

.LoadingPic {
  height: 3em;
  width: auto;
}

.LocationContainer {
  position: relative;
  overflow: hidden;
  height: auto;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  margin: -14px;
  margin-top: 14px;
}

.LocationHeader {
  font-weight: 600;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.LocationDetails {
  font-size: 14px;
  font-weight: 400;
}

.LocationSquare {
  display: block;
  width: 100%;
}

.LocationRow {
  align-items: center;
}

.Logo {
  width: auto;
  height: 4em;
  border-radius: 1em;
}

.MoreResponses {
  flex-direction: row;
  width: 3em;
  height: 3em;
  border-radius: 1.5em;
  background-color: #11b8ff;
  justify-content: center;
  align-items: center;
}

.MoreResponsesText {
  text-align: center;
  color: white;
  font-weight: 500;
  font-size: 10;
}

.NameContainer {
  margin-left: 0em;
}

.PaymentElement {
  text-align: center;
  margin-top: 2em;
  margin-bottom: 2em;
}

.SubmitBtn {
  border-radius: 1em;
  margin-top: 1em;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 5em;
  padding-right: 5em;
  box-shadow: 2px 2px 3px 1px #000;
}

.SubmitBtnText {
  font-size: 1.8em;
  padding-top: 0;
  padding-bottom: 0;
  color: white;
}

.Subtitle {
  font-size: 1.3em;
  font-weight: 600;
  margin-bottom: 0em;
}

.TicketBottomBorder {
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
}

.TicketCaption {
  font-size: 0.8em;
  margin-bottom: 0em;
}

.TicketDisclaimer {
  text-align: center;
  padding-left: 0.5em;
  color: gray;
}

.TicketOptionsBorder {
  font-weight: 600;
  font-size: 14;
  margin-top: 1em;
  margin-bottom: 1em;
  text-align: center;
}

.TicketProcessing {
  text-align: center;
  color: black;
  margin-top: 0em;
  margin-bottom: 4em;
  font-weight: 600;
}

.TicketSummary {
  text-align: center;
}

.TicketTitle {
  font-weight: 600;
}

.useCreditCard {
  text-align: center;
  color: #11b8ff;
  margin-bottom: 0em;
}

.checkoutButtonContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}

.checkoutButton {
  background-color: blue;
}

.SignUp {
  margin-left: 20px;
  margin-right: 20px;
}

.ReferralProgramTitle {
  font-size: 30px;
  font-weight: 800;
  margin-bottom: 10px;
}

.ReferralProgramImage {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: auto;
  width: 100%;
}

.ReferralProgramDescription {
  margin-bottom: 5px;
  margin-top: 5px;
}

.standaloneContainer {
  height: calc(100vh - 160px);
}

.referralProgramCircle {
  flex: 1;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
  width: auto;
  height: 90%;
}

.loadingText {
  text-align: center;
}
.InitialsPic {
  background-color: #8d919c;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 14px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.QRBody {
  background-color: white;
  width: 340px;
  border-radius: 10px;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 1px dashed #929292;
  position: relative;
}

.QRBodyDesktop {
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.QRContainer {
  height: 100vh;
  display: flex;
  background-position: center;
  background-size: cover;
}

.QRContainerDesktop {
  display: flex;
  justify-content: center;
  background-color: white;
  min-height: 100vh;
}

.QRCode {
  backdrop-filter: blur(15px);
  padding-right: 24px;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
}

.QRCodeDesktop {
  margin-top: 50px;
  margin-bottom: 100px;
  width: 45%;
}

.QRCodeImage {
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.QRCodeRedeemedItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f4f5f6;
  border-radius: 5px;
}

.QRFullName {
  text-align: center;
  font-weight: 500;
  margin-top: 8px;
  margin-bottom: 2px;
}

.QRPrompt {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 5px;
  color: #929292;
}

.QRUsername {
  text-align: center;
  font-size: 11px;
  color: #929292;
}

.QRHeader {
  background-color: white;
  border-radius: 1em;
  padding-bottom: 20px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.QRHeaderDesktop {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 60px;
}

.QRProfile {
  height: 40px;
  width: 40px;
  border-radius: 20px;
}

.QRProfileInitials {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: rgb(17, 184, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 20px;
}

.QRSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3px;
  padding-bottom: 3px;
}

.QRSectionDesktop {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: 10px;
}

.QRTicketName {
  text-align: center;
  margin-top: 0em;
  margin-bottom: 0.5em;
}

.QRCodeTicketNumber {
  padding: 5px;
  padding-left: 8px;
  padding-right: 8px;
  background-color: #f4f5f6;
  border-radius: 5px;
  position: absolute;
  bottom: -12px;
  display: flex;
  align-items: center;
}

.QRCodeTicketNumberDesktop {
  padding: 5px;
  padding-left: 8px;
  padding-right: 8px;
  background-color: #f4f5f6;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.QRTitle {
  font-size: 15px;
  font-weight: 500;
}

.QRTicketContainerDesktop {
  margin-top: 20px;
  border-radius: 12px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #929292;
}

.QRRoundedButton {
  padding: 7px 10px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  cursor: pointer;
}

.Checkout {
  width: 100%;
  padding-bottom: 14px;
}

.CheckoutDesktop {
  width: 100%;
  padding-bottom: 14px;
  margin-top: 20px;
}

.CheckoutDescription {
  width: 95%;
  margin-top: 0.3em;
  margin-bottom: 1.3em;
  font-size: 0.75em;
  color: black;
}

.CheckoutDescriptionMulti {
  width: 95%;
  margin-top: 0.3em;
  margin-bottom: 0.8em;
  font-size: 0.75em;
  color: black;
}

.CheckoutHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 24px;
}

.CheckoutLogo {
  width: auto;
  height: 4em;
  border-radius: 1em;
  box-shadow: 0.5px 0.5px 0px 0px #000;
}

.CheckoutTitle {
  font-weight: 600;
  font-size: 24px;
  padding-left: 14px;
}

.CheckoutWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 14px;
}

.CheckoutWrapperDesktop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.CheckoutHeaderDesktop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 97%;
  padding-top: 24px;
}

.CloseButtonDesktop {
  height: 1.8em;
  width: 1.8em;
  cursor: pointer;
}

.CheckoutTitleDesktop {
  font-size: 1.4em;
  font-weight: 600;
}

.CheckoutContentDesktop {
  width: 550px;
}

.CheckoutFormDesktop {
  z-index: 2;
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(244, 245, 246, 0.8);
  backdrop-filter: blur(30px);
}

.CheckoutFormMobile {
  z-index: 3;
  background: linear-gradient(white, 10%, #b9b9b9, #f7f7f7, #b9b9b9);
  position: absolute;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.CheckoutFormHeader {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 60px;
}

a {
  cursor: pointer;
  text-decoration: none;
}

.FooterDesktop {
  width: 70%;
  padding-block: 40px;
  background-color: white;
}

.Footer {
  width: 100%;
  padding-block: 24px;
}

.FooterTopDesktop {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 40px;
  align-items: flex-start;
  justify-content: space-between;
}

.FooterTop {
  display: flex;
  flex-direction: column;
  padding-inline: 24px;
}

.FooterBottomDesktop {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
  align-items: center;
  justify-content: space-between;
}

.FooterBottom {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 7px;
  margin-inline: 24px;
  margin-bottom: 16px;
  padding-left: 7px;
}

hr {
  margin-inline: 0 !important;
}

.LogoText {
  font-weight: 500;
}

.LogoFooter {
  width: auto;
  height: 2em;
}

.LogoFooterContainer {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.CreateEventPill {
  cursor: pointer;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding-block: 10px;
  padding-inline: 25px;
  border-radius: 100px;
}

.OSContainer {
  display: flex;
  flex-direction: row;
  gap: 7px;
}

.OSPill {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding-block: 9px;
  padding-inline: 10px;
  background-color: #f4f5f6;
  border: 0.5px solid #b9b9b9;
  border-radius: 100px;
  cursor: pointer;
  transition: 0.1s ease;
}

.OSText {
  color: black;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.FooterInfoContainerDesktop {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 60px;
}

.FooterInfoContainer {
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  margin-top: 40px;
  padding-left: 7px;
  gap: 60px;
}

.FooterInfoColumn {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.FooterInfoColumn p {
  transition: 0.1s ease;
}

.FooterInfoColumn p:hover {
  color: black !important;
}

.FooterInfoTitle {
  font-size: 14px;
  font-weight: 500;
}

.FooterInfoText {
  font-size: 14px;
  color: #929292;
  font-weight: 500;
  cursor: pointer;
}

.FooterInfoSocials {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 10px;
}

.FooterAppIcon {
  transition: 0.1s ease;
}

.FooterAppIcon:hover {
  cursor: pointer;
  filter: brightness(0);
}

.BottomRow {
  display: flex;
  flex-direction: row;
  width: 80%;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8em;
  font-weight: 400;
  padding-top: 2em;
}

.PpandTos {
  display: flex;
  flex-direction: row;
  width: 40%;
  justify-content: space-between;
  color: #929292;
}

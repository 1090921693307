.FeaturedEventList {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: auto;
  gap: 7px;
  max-width: 100%;
}

.FeaturedListEventPage {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  overflow-x: scroll;
  /* padding-inline: 36vw; */
}

.FeaturedEventsTitle {
  color: white;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
}

.FeaturedEventsText {
  color: white;
  font-size: 14px;
  cursor: pointer;
}

.EventCategoriesContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.FeaturedCitiesContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.EventCardImageDesktop {
  /* for responsive event images that scale with display sizes */
  /* clamp(min width/height, preferred size, max width/height)*/
  width: clamp(84px, calc((100vw - (36vw + 42px)) / 4 - 28px), 428px);
  height: clamp(84px, calc((100vw - (36vw + 42px)) / 4 - 28px), 428px);
  border-radius: 12px;
  cursor: pointer;
}

.CardImageMobile {
  width: 160px;
  height: 160px;
  border-radius: 8px;
  cursor: pointer;
}

.CardDateTextContainer {
  vertical-align: top;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.SearchBarInput {
  padding: 0;
  font-size: 15px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  width: 100%;
  background-color: transparent;
  border: 0px solid black;
  resize: none;
}

.SearchBarInput::placeholder {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

/**/
.LightSearchBarInput {
  color: white;
}

.DarkSearchBarInput::placeholder {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: #929292;
}

.SearchBarButton {
  border: 0px solid black;
  background-color: black;
  color: white;
  margin: 7px;
  border-radius: 8px;
  padding-inline: 12px;
  padding-block: 2px;
  cursor: pointer;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.SearchBarBgDesktop {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
}

.SearchBgMobile {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #f4f5f6;
  width: 100vw;
  height: 100vh;
  z-index: 9;
}

.SearchBarContainerDesktop {
  margin: 0px calc(18vw - 14px) 60px calc(18vw - 14px);
  position: absolute;
  top: 257px;
  left: 0;
  padding: 14px;
  background-color: white;
  border-radius: 20px;
  width: calc(100vw - (2 * (18vw)));
}

.SearchBarContainerMobile {
  margin-inline: 14px;
  position: absolute;
  left: 0;
  border-radius: 20px;
  width: calc(100vw - 28px);
}

.SearchOptionsContainer {
  display: flex;
  flex-direction: row;
  gap: 7px;
  margin-top: 14px;
}

.ShareTicketsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

/* DESKTOP */
.ShareTicketsHeaderDesktop {
  z-index: 2;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ShareTicketsItemContainerDesktop {
  z-index: 2;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 40%;
  border-radius: 10px;
  border: none;
  padding: 10px;
  margin: 5px;
}

.ShareTicketsTitleDesktop {
  font-weight: 600;
  font-size: 18px;
}

/* MOBILE */
.ShareTicketsHeaderMobile {
  z-index: 2;
  position: absolute;
  padding-top: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ShareTicketsItemContainer {
  z-index: 2;
  align-self: center;
  width: 95%;
  border-radius: 10px;
  margin: 5px;
}

.ShareTicketsDottedLine {
  border-style: dashed;
  border-width: 0.8px;
  border-color: #929292;
  border-radius: 1px;
  margin-block: 10px;
  width: 100%;
}

.ShareTicketsRoundedButton {
  padding: 5px 7px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  background-color: #f4f5f6;
  font-size: 16px;
  cursor: pointer;
}

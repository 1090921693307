.LoginContainer {
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.LoginHeaderText {
  color: #000;
  font-size: 16px;
  font-weight: 600;
}

.LoginHeaderTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 24px;
}

.LoginHeaderTextDescription {
  color: #b9b9b9;
  font-weight: 500;
  font-size: 12px;
}

.LoginLogo {
  height: 2em;
  width: auto;
  margin-bottom: 14px;
}

input[type="file"] {
  display: none;
}

/* DESKTOP */
.SearchBarContainer {
  box-sizing: border-box;
  background-color: #fafafa;
  border: 0.8px solid #b9b9b9;
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 14px;
}

.SearchBarIconAndInputContainer {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  width: 100%;
  padding: 12px;
}

.ConfirmActionContainer {
  gap: 7px;
  padding: 14px;
  border-radius: 8px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  position: fixed;
  display: flex;
  box-shadow: -3px 7px 25px 1px rgba(0, 0, 0, 0.3);
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  z-index: 1001;
}

.CreatorPanelContainer {
  padding: 28px 48px 0px 48px;
}

/* LargePopupModalContainer */
.LargePopupPanelContainer {
  position: fixed;
  top: 14px;
  right: 14px;
  width: 500px;
  height: calc(100vh - 28px);
  border-radius: 8px;
  background-color: white;
  box-shadow: 0px 1px 20px 0px #00000045;
  box-sizing: border-box;
  z-index: 5;
}

.LargePopupPanelClose {
  width: 31px;
  height: 31px;
  background-color: #f4f5f6;
  border-radius: 6px;
  display: grid;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.LargePopupPanelBlackButton {
  background-color: black;
  box-sizing: border-box;
  padding-inline: 16px;
  padding-block: 8px;
  border: 0px solid black;
  border-radius: 40px;
  cursor: pointer;
  box-shadow: -3px 7px 25px 1px #0000004d;
}

.LargePopupPanelGrayButton {
  background-color: #f4f5f6;
  box-sizing: border-box;
  padding-inline: 16px;
  padding-block: 8px;
  border: 0px solid black;
  border-radius: 40px;
  cursor: pointer;
  transition: 0.2s ease;
}

.LargePopupPanelBlackButton,
.LargePopupPanelGrayButton * {
  cursor: pointer;
}

.LargePopupPanelGrayButton:hover {
  background-color: black;
  color: white;
}

/* Mobile */
.StandardContainer {
  margin-bottom: 12px;
  padding: 14px;
  background-color: white;
  border-radius: 8px;
}

.HeaderStandardContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.ValueStandardContainer {
  color: #929292;
}

.modalBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modalContent {
  width: 452px;
  overflow-y: scroll;
  background-color: white;
  padding-block: 14px;
  border-radius: 20px;
  box-shadow: -3px 7px 25px 1px #0000004d;
  display: flex;
  flex-direction: column;
}

.wideModalContent {
  width: 640px;
  overflow-y: scroll;
  background-color: white;
  padding-block: 14px;
  border-radius: 20px;
  box-shadow: -3px 7px 25px 1px #0000004d;
  display: flex;
  flex-direction: column;
}

.smallModalContent {
  width: 366px;
  overflow-y: scroll;
  background-color: white;
  padding-block: 14px;
  border-radius: 20px;
  box-shadow: -3px 7px 25px 1px #0000004d;
  display: flex;
  flex-direction: column;
}

.blueGradient {
  background: rgb(0, 122, 255);
  background: linear-gradient(
    90deg,
    rgba(0, 122, 255, 1) 0%,
    rgba(0, 79, 199, 1) 40%,
    rgba(0, 122, 255, 1) 98%
  );
}

.redGradient {
  background: rgb(223, 72, 64);
  background: linear-gradient(
    90deg,
    rgba(223, 72, 64, 1) 0%,
    rgba(254, 64, 41, 1) 40%,
    rgba(223, 72, 64, 1) 98%
  );
}

.normalTextField {
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: #000 solid 1px !important;
  }
}

hr {
  width: 100% !important;
}

.subtext {
  color: #929292;
  font-size: 14px;
  font-weight: 400;
}

.titleInput {
  font-size: 27px;
  font-weight: 600;
  padding: 0;
  border: 0px solid black;
}
.titleInputError {
  font-size: 27px;
  font-weight: 600;
  padding: 0;
  border: 0px solid black;
}

.titleInput::placeholder,
.descriptionTextArea::placeholder {
  color: #b9b9b9;
}

.titleInputError::placeholder {
  color: #df4840;
}

.titleInput:focus,
.descriptionTextArea:focus {
  outline: none;
}

.descriptionInput {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 17px;
  border: 0px solid black;
  border-bottom-width: 1px;
  border-bottom-color: #b9b9b9;
  padding-inline: 0 !important;
  margin-top: 5px;
  padding-block: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.descriptionHeader {
  font-weight: 500;
}

.containerButton {
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 14px;
  cursor: pointer;
}

.datesContainer {
  padding: 14px;
  border-radius: 8px;
  background-color: #f4f5f6;
  width: 100%;
}

.dateWrapper {
  display: grid;
  grid-template-columns: 1fr 4fr;
  align-items: center;
  gap: 4px;
}

.dateContainer {
  border: 0.5px solid #b9b9b9;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dateContainerText {
  font-size: 15px;
  font-weight: 400;
  padding-block: 8px;
  padding-inline: 14px;
}

.eventThemesButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 7px;
}

/* Add these styles to your CSS file */
.descriptionTextAreaContainer {
  box-sizing: border-box;
  border: 0.5px solid #b9b9b9;
  background-color: #eeeef0;
  border-radius: 8px;
  width: 100%;
  padding: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.descriptionTextArea {
  padding: 0;
  font-size: 15px;
  height: 30vh;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  width: 100%;
  background-color: transparent;
  border: 0px solid black;
  resize: none;
}

.eventImagePlaceholder {
  width: calc((100vw - (36vw + 14px)) * (400 / 880));
  height: calc((100vw - (36vw + 14px)) * (400 / 880));
  background-color: white;
  display: grid;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  margin-bottom: 14px;
  cursor: pointer;
}

.formQuestionAddButton {
  border: 0.5px solid #929292;
  border-radius: 8px;
  padding: 10px;
  justify-content: center;
  gap: 6px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: 0.3s;
}

.formQuestionAddButtonLight:hover {
  background-color: #f4f5f6;
}

.formQuestionAddButtonDark:hover {
  background-color: #313135;
}

.inputTextAreaContainer {
  border: 0.5px solid #b9b9b9;
  border-radius: 5px;
  width: 100%;
}

.photoGallerySample {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 14px;
  max-height: 45vh;
  overflow: scroll;
}

.photoGallerySample img {
  width: calc((50vw - 70px) / 4);
  height: calc((50vw - 70px) / 4);
  border-radius: 8px;
  object-fit: cover;
}

.eventImageContainer {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.eventImageEditButton {
  position: absolute;
  width: 36px;
  height: 36px;
  display: grid;
  justify-content: center;
  align-items: center;
  right: 12px;
  bottom: 28px;
  background-color: white;
  border-radius: 8px;
  cursor: pointer;
}

.DraftButton {
  padding: 10px 30px;
  border-radius: 8px;
  background-color: #f4f5f6;
  font-size: 14px;
  font-weight: 600;
  width: 100%;
  text-align: center;
}

.DeleteDraftIconsWrapper {
  display: flex;
  justify-content: space-between;
  padding-inline: 14px;
  padding-bottom: 7px;
}

.DeleteDraftButton {
  padding: 14px;
  text-align: center;
  border-radius: 8px;
  background-color: #df4840;
  color: white;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.LocationItem {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-inline: 10px;
  margin-block: 7px;
  cursor: pointer;
  padding: 10px;
}

.LocationItemLight {
  transition: border-radius 0.2s ease, background-color 0.2s ease;
}

.LocationItemLight:hover {
  border-radius: 8px;
  background-color: #f4f5f6;
}

.LocationItemDark {
  transition: border-radius 0.2s ease, background-color 0.2s ease;
}

.LocationItemDark:hover {
  border-radius: 8px;
  background-color: #313135;
}

.SearchModalBG {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}

.SearchModal {
  width: calc(24vw - 28px);
  margin-bottom: 14px;
  position: absolute;
  z-index: 5;
}

.LocationSubtext {
  color: #b9b9b9;
  font-size: 14px;
}

.EditButton {
  cursor: pointer;
  padding: 4px;
  border-radius: 6px;
  transition: 0.3s;
}

.EditButtonLight {
  color: #000;
}

.EditButtonDark {
  color: #fff;
}

.EditButtonLight:hover {
  color: #fff;
  background-color: #000;
}

.EditButtonDark:hover {
  color: #000;
  background-color: #fff;
}

.TrashButton {
  color: #df4840;
  cursor: pointer;
  padding: 4px;
  border-radius: 6px;
  transition: 0.3s;
}

.TrashButton:hover {
  background-color: #df4840;
  color: #fff;
}

.TicketType {
  gap: 14px;
  cursor: pointer;
  padding: 10px;
  border-radius: 8px;
}

.TicketTypeLight {
  border: 1.5px solid #f4f5f6;
  background-color: #f4f5f6;
  transition: background-color 0.3s, border 0.3s;
}

.TicketTypeDark {
  border: 1.5px solid #313135;
  background-color: #313135;
  transition: background-color 0.3s, border 0.3s;
}

.TicketTypeLight:hover {
  background-color: #fff;
  border: 1.5px solid #000;
}

.TicketTypeDark:hover {
  background-color: #000;
  border: 1.5px solid #fff;
}

.TicketTypeSelectedLight {
  background-color: #fff;
  border: 1.5px solid #000;
}

.TicketTypeSelectedDark {
  background-color: #000;
  border: 1.5px solid #fff;
}

.TicketTypeSelectedDarkDisabled {
  background-color: #666666;
  border: 1.5px solid #fff;
}

.TicketTypeSubtext {
  color: #929292;
  font-size: 13px;
}

.ArrowDropdownOption {
  display: flex;
  align-items: center;
  gap: 7px;
  padding: 4px 7px;
  cursor: pointer;
  color: #666;
  border-radius: 6px;
  transition: 0.3s;
}

.ArrowDropdownOptionLight:hover {
  background-color: #ededed;
  color: #000;
}

.ArrowDropdownOptionDark:hover {
  background-color: #666;
  color: #fff;
}

input:focus {
  outline: none;
}

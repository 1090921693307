.CardEventSummary {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.CardEventSummaryDesktop {
  display: flex;
  flex-direction: row;
  border-radius: 1em;
}

.CardEventSummaryOnConfirmation {
  display: flex;
  flex-direction: row;
  margin-left: 14px;
}

.CardEventSummaryOnConfirmationDesktop {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-self: center;
}

.CardImage {
  width: 22%;
  height: 22%;
  border-radius: 8px;
  cursor: pointer;
}

.CardLongDateContainer {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.CardDateContainer {
  vertical-align: top;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.CardDateTextProfile {
  font-size: 0.8em;
  color: black;
  font-weight: 400;
}

.CardAddress {
  width: 20em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 0.8em;
  color: #929292;
}

.CardEventLocationDetailsText {
  font-size: 0.8em;
  color: #929292;
}

.CardEventTitle {
  margin-bottom: 5px;
  font-weight: 600;
}

.CardHost {
  font-size: 0.8em;
  color: #929292;
}

.CardHostBlue {
  color: #11b8ff;
}

.DraftImage {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px;
  background-color: #e8e8e8;
  aspect-ratio: 1 / 1;
}
